<template>
  <div class="loginParent">
    <form id="loginPanel" @submit="login">
      <h2>{{ $t("login.title") }}</h2>
      <input
        class="input"
        id="usernameInput"
        type="text"
        v-bind:placeholder="$t('login.userIdentification')"
        v-model="name"
        required
      />
      <input
        class="input"
        id="passwordInput"
        type="password"
        v-bind:placeholder="$t('user.password')"
        v-model="password"
        required
      />
      <button class="button loginButton" type="submit">{{ $t("login.title") }}</button>
      <span v-if="loginError != null" class="loginError">{{ loginError }}</span>
    </form>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";
// import { NotAllowedToEnterAppError } from "@/errors/NotAllowedToEnterAppError";

export default {
  name: "Login",
  data() {
    return {
      name: "",
      password: "",
      loginError: null,
    };
  },
  methods: {
    login(e) {
      e.preventDefault();

      const { name, password } = this;

      // console.log("login: name, password: ", name, password);

      // TODO, throw error in auth.js when empty
      this.loginError = null;
      if (name == "" || password == "") {
        this.loginError = "Login Felder dürfen nicht leer sein";
        return;
      }

      this.$store
        .dispatch(AUTH_REQUEST, { name, password })
        .then(() => {
          this.$router.push(this.$i18nRoute({name: "Overview"}));
        })
        .catch((error) => {
          console.error("login error", error);
          this.loginError = null;
          // if (error instanceof NotAllowedToEnterAppError) {
          //   this.loginError =
          //     "Dieser Benutzer ist nicht berechtigt sich an dieser Anwendung anzumelden";
          // } else {
          this.loginError = this.$t("login.userOrPasswordWrong");
          // }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.loginParent {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #286145;
  background-image: url("../assets/zeck_stripes.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#loginPanel {
  display: flex;
  flex-direction: column;
  background-color: #333;
  color: white;
  margin: auto;
  padding: 1em;
  padding-top: 0;
  text-align: center;
  border-radius: 0.25em;
  width: 20em;

  .input {
    display: inline-block;
    margin-bottom: 0.5em;
    padding: 0.5em;
  }
}

.loginButton {
  display: block;
  width: 100%;
  padding: 0.5em;
}

.loginError {
  color: #ffaaaa;
  margin-top: 1em;
}
</style>